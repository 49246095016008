#editor-container {
  font-family: "Inter", sans-serif, "Open Sans", Helvetica, sans-serif;
  font-size: 1.5rem;
  height: 100%;
  margin: 0 auto;
  width: 100%;
}
#editor-container .ql-editor {
  max-height: 100%;
  overflow: auto;
  padding-bottom: 25px;
  font-family: "Inter", sans-serif, "Open Sans", Helvetica, sans-serif;
  font-size: 1rem;
  color: var(--color-text-primary) !important;
  width: 100%;
}
.ql-container.ql-bubble {
  max-width: 100% !important;
  word-break: break-all !important;
}
.ql-editor h1,
.ql-editor h2,
.ql-editor p,
.ql-editor span {
  color: var(--color-text-primary) !important;
}
.ql-formats {
  margin: 0 !important;
  padding: 0 !important;
}
.ql-toolbar {
  border: 1px solid var(--color-border-secondary) !important;
  border-radius: 8px !important;
  background-color: var(--color-bg-secondary) !important;
  width: 101% !important;
}
.ql-formats button {
  height: 30px !important;
  width: 30px !important;
  padding: 5px !important;
  border-right: 1px solid var(--color-border-tertiary) !important;
}
.ql-header:last-child {
  border: none !important;
}
.ql-tooltip-arrow {
  display: none !important;
}

.ql-active {
  background-color: var(--color-bg-secondary) !important;
}
.ql-active:first-child {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
.ql-active:last-child {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
