.navigation-desk {
  display: none;
}
.navigation-mobi {
  display: block;
}
@media (min-width: 375px) {
}

@media (min-width: 480px) {
}

@media (min-width: 640px) {
  .navigation-desk {
    display: block;
  }
  .navigation-mobi {
    display: none;
  }
}

@media (min-width: 768px) {
}

@media (min-width: 830px) {
}

@media (min-width: 1024px) {
}

@media (min-width: 1280px) {
}
@media (min-width: 1300px) {
}

@media (min-width: 1400px) {
}
@media (min-width: 1500px) {
}
@media (min-width: 1600px) {
}
