@use "../../../styles/_theme_values" as themeValue;
@use "../../../styles/_theme_creator" as themeAction;

.border-box {
  padding: 2px 6px 2px 6px;
  border-radius: 6px;
  border: 1px;
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 4px;
  border: 1px solid rgba(51, 55, 65, 1);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.main-block {
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.block-tags {
  display: grid;
  grid-template-columns: minmax(0, 5fr) minmax(0, 2fr);
  gap: 1rem;
  padding-block: 1rem;
  justify-self: center;
  align-items: center;
}

.main-card {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: 1rem;
}

.mmm {
  min-height: 200px;
  padding-bottom: 2rem;
}

.tag-block-main {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  min-height: 100px;
  border: 2px solid transparent;
}

.tag-block-main-selected {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  min-height: 100px;
  border: 2px solid rgba(155, 138, 251, 1);
}

.pagination-block {
  border-bottom: 1px solid rgba(31, 36, 47, 1);
  padding-block: 2rem;
}
