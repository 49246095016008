@use "_theme_values" as themeValue;
@use "_theme_creator" as themeAction;
@import "onboard2";
@import "onboard";
$unit: 2px;

html {
  --color-border-primary: #d0d5dd;
  --color-border-secondary: #eaecf0;
  --color-text-error-primary: #f97066;
  --color-text-primary: #101828;
  --color-text-secondary: #344054;
  --color-text-tertiary: #475467;
  --color-text-quaternary: #667085;
  --color-bg-primary: #ffffff;
  --color-bg-secondary: #f9fafb;
  --color-bg-tertiary: #f4f3ff;
  --color-bg-quaternary: #eaecf0;
  --color-bg-error-secondary: #f5f5f6;
  --color-brand-primary: #6938ef;
  --color-brand-secondary: #ebe9fe;

  --spacing-xl: #{$unit * 8};
  --spacing-md: #{$unit * 4};
  --spacing-xxs: #{$unit * 1};
  --spacing-sm: #{$unit * 2};
  --spacing-3xl: #{$unit * 12};
  --spacing-4xl: #{$unit * 16};
  --spacing-9xl: #{$unit * 48};
  --spacing-7xl: #{$unit * 32};

  --radius-md: #{$unit * 4};
  --radius-sm: #{$unit * 3};
  --radius-2xl: #{$unit * 8};
}

html[data-theme="dark"] {
  --color-border-primary: #333741;
  --color-border-secondary: #1f242f;
  --color-text-error-primary: #f97066;
  --color-text-primary: #f5f5f6;
  --color-text-secondary: #cecfd2;
  --color-text-tertiary: #94969c;
  --color-text-quaternary: #94969c;
  --color-bg-primary: #0c111d;
  --color-bg-secondary: #161b26;
  --color-bg-tertiary: #1f242f;
  --color-bg-quaternary: #333741;
  --color-bg-error-secondary: #f5f5f6;
  --color-brand-primary: #7a5af8;
  --color-brand-secondary: #6938ef;
}

html.transition,
html.transition *,
html.transition *:before,
html.transition *:after {
  transition: cubic-bezier(0.68, -0.55, 0.27, 01.55) 420ms !important;
  transition-delay: 0 !important;
}

.display-sm-semibold {
  @include themeAction.classCreator("Inter", 30px, 600, 38px, 0em);
}

.display-md-semibold {
  @include themeAction.classCreator("Inter", 2.25rem, 600, 2.75rem, 0em);
  // Display md/Semibold
}
.display-lg-semibold {
  @include themeAction.classCreator("Inter", 48px, 600, 60px, -2%);

  //styleName: Display lg/Semibold;
}
.text-sm-medium {
  @include themeAction.classCreator("Inter", 14px, 500, 20px, 0em);
  //styleName: Text sm/Medium;
}

.text-sm-medium {
  @include themeAction.classCreator("Inter", 14px, 500, 20px, 0em);
  //styleName: Text sm/Medium;
}

.text-sm-semibold {
  @include themeAction.classCreator("Inter", 0.875rem, 600, 1.25rem, 0rem);
  /* Text sm/Semibold */
}
.text-md-semibold {
  @include themeAction.classCreator("Inter", 16px, 600, 24px, 0rem);
  //styleName: Text md/Semibold;
}
.text-sm-regular {
  @include themeAction.classCreator("Inter", 0.875rem, 400, 1.25rem, 0em);
  //styleName: Text sm/Regular;
}
.text-lg-medium {
  @include themeAction.classCreator("Inter", 18px, 500, 28px, 0em);
  //styleName: Text lg/Medium;
}
.text-lg-regular {
  @include themeAction.classCreator("Inter", 18px, 400, 28px, 0em);
  //styleName: Text lg/Regular;
}
.text-xs-medium {
  @include themeAction.classCreator("Inter", 0.75rem, 500, 1.25rem, 0em);
  //styleName: Text xs/Medium;
}

.text-xl-semibold {
  @include themeAction.classCreator("Inter", 20px, 600, 30px, 0em);
  //styleName: Text xl/Semibold;
}

.text-md-regular {
  @include themeAction.classCreator("Inter", 16px, 400, 24px, 0em);
  //styleName: Text md/Regular;
}

.text-md-medium {
  @include themeAction.classCreator("Inter", 16px, 500, 24px, 0em);
  //styleName: Text md/Medium;
}
.text-lg-semibold {
  @include themeAction.classCreator("Inter", 18px, 600, 28px, 0em);
  //styleName: Text lg/Semibold;
}

.text-xl-medium {
  @include themeAction.classCreator("Inter", 20px, 500, 30px, 0em);

  //styleName: Text xl/Medium;
}
.text-xl-regular {
  @include themeAction.classCreator("Inter", 1.25rem, 400, 1.875rem, 0em);
  //styleName: Text xl/Regular;
}

// text color classes
.text-primary {
  color: var(--color-text-primary);
}
.text-brand-primary {
  color: var(--color-brand-primary);
}
.text-brand-secondary {
  color: var(--color-brand-secondary);
}
.text-tertiary {
  color: var(--color-text-tertiary);
}
.text-secondary {
  color: var(--color-text-secondary);
  // Colors/Text/text-secondary (700)
}
.text-quaternary {
  color: var(--color-text-quaternary);
  // Colors/Text/text-secondary (700)
}
.text-error {
  color: var(--color-text-error-primary);
  // Colors/Text/text-secondary (700)
}

//background color

.bg-primary {
  background-color: var(--color-bg-primary);
}
.bg-brand-primary {
  background-color: var(--color-brand-primary);
}
.bg-secondary {
  background-color: var(--color-bg-secondary);
}
.bg-brand-secondary {
  background-color: var(--color-brand-secondary);
}
.bg-tertiary {
  background-color: var(--color-bg-tertiary);
}
.bg-quaternary {
  background-color: var(--color-bg-quaternary);
}
.bg-error-secondary {
  background-color: var(--color-bg-error-secondary);
}

//border colors

.border-primary {
  border-color: var(--color-border-primary);
}
.border-brand-primary {
  border-color: var(--color-brand-primary);
}
.border-secondary {
  border-color: var(--color-border-secondary);
}
.border-brand-secondary {
  border-color: var(--color-brand-secondary);
}

// text button
.btn-tertiary {
  color: var(--color-text-tertiary);
}
.btn-secondary-fg {
  color: themeValue.$button-secondary-color;
  //  colors/Components/Buttons/Secondary/button-secondary-fg
}
.scal-t-label {
  margin-bottom: themeValue.$spacing-sm;
  color: themeValue.$text-secondary-color;
}
.container-main-cur {
  max-width: 100%;
  margin-inline: auto;
  padding-left: 5px;
  padding-right: 5px;
  /* border: 1px solid black; */
}
@media (min-width: 375px) {
}

@media (min-width: 480px) {
  .container-main-cur {
    max-width: 480px;
  }
}

@media (min-width: 640px) {
  .container-main-cur {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container-main-cur {
    max-width: 768px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (min-width: 830px) {
  .container-main-cur {
    max-width: 830px;
  }
}

@media (min-width: 1024px) {
  .container-main-cur {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container-main-cur {
    max-width: 1280px;
  }
}
@media (min-width: 1300px) {
  .container-main-cur {
    max-width: 1300px;
  }
}

@media (min-width: 1400px) {
  .container-main-cur {
    max-width: 1400px;
  }
}
@media (min-width: 1500px) {
  .container-main-cur {
    max-width: 1500px;
  }
}
@media (min-width: 1600px) {
  .container-main-cur {
    max-width: 1600px;
  }
}
