@use "../../../styles/_theme_values" as themeValue;
@use "../../../styles/_theme_creator" as themeAction;

.top-bar {
//   background: linear-gradient(90deg, #000000 2.38%, rgba(0, 0, 0, 0) 97.77%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: themeValue.$spacing-6xl 112px themeValue.$spacing-6xl   112px;
  border-radius: var(--radius-none, 0rem);
  background-repeat: no-repeat;
  background-size: cover;




}
