$primary-font: "inter";

// shadow classes
$shadow-md: 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
$shadow-sm: 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
$shadow-xl: 0px 20px 24px -4px #10182814;
// text

$text-primary-color: rgba(245, 245, 246, 1);
$text-secondary-color: rgba(206, 207, 210, 1);
$text-tertiary-color: rgba(148, 150, 156, 1);

// button color
$button-tertiary-color: rgba(206, 207, 210, 1);
$button-secondary-color: #cecfd2;
// bg classes

$bg-primary: #0c111d;
$bg-secondary: rgba(22, 27, 38, 1);

// spacing class
$spacing-sm: 0.375rem;
$spacing-md: 8px;
$spacing-lg: 12px;
$spacing-xl: 16px;
$spacing-3xl: 24px;
$spacing-4xl: 32px;
$spacing-5xl: 40px;
$spacing-6xl: 48px;
$spacing-7xl: 64px;

// radiaus
$radius-sm: 6px;
$radius-4x: 24px;

// border
$border-secondary: #1f242f;
