@use "../../styles/_theme_values" as themeValue;
@use "../../styles/_theme_creator" as themeAction;

.selected-btn {
  padding: themeValue.$spacing-md themeValue.$spacing-lg themeValue.$spacing-md
    themeValue.$spacing-lg;
  border-radius: themeValue.$radius-sm;
  gap: themeValue.$spacing-md;
  white-space: nowrap;
}
.non-selected-btn {
  padding: themeValue.$spacing-md themeValue.$spacing-lg themeValue.$spacing-md
    themeValue.$spacing-lg;
  border-radius: themeValue.$radius-sm;
  gap: themeValue.$spacing-md;
  white-space: nowrap;
  cursor: pointer;
}
