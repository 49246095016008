@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Barlow&family=Cabin&family=Dancing+Script&family=Inter&family=Libre+Baskerville:ital@1&family=Montserrat&family=Tenor+Sans&display=swap");

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
#root {
  height: 100%;
}
body {
  font-family: "Inter", sans-serif;
  background-color: var(--color-bg-secondary);
}
.react-google-flight-datepicker .date-picker {
  background-color: var(--color-bg-secondary) !important;
  color: var(--color-text-primary) !important;
  width: 400px;
}
.react-google-flight-datepicker .date {
  background-color: var(--color-bg-secondary) !important;
  color: var(--color-text-primary) !important;
}
.react-google-flight-datepicker .dialog-date-picker {
  background-color: var(--color-bg-secondary) !important;
  color: var(--color-text-primary) !important;
}
.react-google-flight-datepicker .weekdays {
  color: var(--color-text-primary) !important;
}
.react-google-flight-datepicker .month-calendar {
  color: var(--color-text-secondary) !important;
}
.react-google-flight-datepicker .day.disabled {
  color: var(--color-text-quaternary) !important;
}

.react-google-flight-datepicker .day.selected.hovered {
  border-top-left-radius: 22px;
  border-bottom-left-radius: 22px;
}
.react-google-flight-datepicker .day.selected.hovered.end {
  border-top-right-radius: 22px;
  border-bottom-right-radius: 22px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.react-google-flight-datepicker .day.hovered {
  color: var(--color-text-quaternary) !important;
}
.react-google-flight-datepicker .reset-button,
.react-google-flight-datepicker .dialog-footer {
  display: none;
}
.react-google-flight-datepicker .dialog-content {
  border: 0px solid black;
}
.react-google-flight-datepicker .dialog-date-picker {
  left: -370px;
}
.react-google-flight-datepicker .dialog-header {
  justify-content: end;
}
.react-google-flight-datepicker .dialog-header .date-picker-input {
  width: 426px;
}
/* .css-1i9ja10-MuiTableCell-root {
  border: none !important;
} */
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: var(--color-bg-tertiary);
  box-shadow: inset 0 0 5px var(--color-border-primary);
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-border-primary);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-border-secondary);
}

.blocking-loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  height: 100%;
  display: flex;
  background-color: rgba(255, 255, 255, 0.95);
  justify-content: center;
  align-items: center;
}

.app-tabs-bar::-webkit-scrollbar {
  display: none;
}

/*JSON editor customization start*/
.scal-json-formatter .ant-input-number-input {
  border-radius: 8px !important;
  border: 1px solid var(--color-border--primary) !important;
  background: var(--color-bg-secondary) !important;
  box-shadow: 0px 1px 2px 0px var(--color-bg-primary);
  color: var(--color-text-primary) !important;
  height: 40px !important;
  vertical-align: middle;
  outline: none !important;
  width: 200px !important;
}
.scal-json-formatter .ant-input-number {
  background-color: transparent;
  border: none;
}
.scal-json-formatter .ant-input-number:focus {
  outline: none;
}
.ant-input-number:hover {
  border-color: var(--color-brand-primary);
}
.scal-json-formatter .ant-input-number-handler-wrap {
  background: #fff !important;
  margin-right: 0% !important;
}
.scal-json-formatter .ant-select-single .ant-select-selector,
.scal-json-formatter .ant-input {
  border-radius: 8px !important;
  border: 1px solid var(--color-border-primary) !important;
  background: var(--color-bg-secondary) !important;
  box-shadow: 0px 1px 2px 0px var(--color-bg-primary);
  color: var(--color-text-primary) !important;
  height: 40px !important;
  vertical-align: middle;
  outline: none !important;
}

.scal-json-formatter .ant-select-show-search {
  width: 350px !important;
}
.scal-json-formatter .ant-select-single .ant-select-selector:hover,
.scal-json-formatter .ant-input:hover,
.scal-json-formatter .ant-input-number-input {
  border: 1px solid var(--color-brand-primary) !important;
}
.ant-select-single .ant-select-selector:focus,
.scal-json-formatter .ant-input:focus,
.scal-json-formatter .ant-input-number-input:focus {
  border: 1px solid var(--color-brand-primary) !important;
  outline: none !important;
}
.scal-json-formatter .ant-select-selection-search-input {
  margin-top: 8px !important;
}
.scal-json-formatter .ant-input {
  margin-top: 10px !important;
}
.scal-json-formatter .objectContent {
  margin-top: 35px;
}
.scal-json-formatter .jsonEditorContainer .tools {
  margin-top: 12px !important;
  width: 200px;
  display: flex;
  align-items: center;
}
.scal-json-formatter .jsonEditorContainer {
  width: 100% !important;
}
.ant-select-dropdown {
  background-color: var(--color-bg-secondary) !important;
  color: var(--color-text-tertiary) !important;
  border-radius: 8px !important;
}
.ant-select-dropdown div div {
  color: var(--color-text-tertiary);
}
.scal-json-formatter .indexLine {
  margin-top: 40px !important;
}
.scal-json-formatter .ant-select-selection-item {
  margin-top: 8px !important;
}

.scal-json-formatter .anticon.anticon-plus-square svg {
  display: none;
}
.scal-json-formatter .anticon.anticon-plus-square {
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.scal-json-formatter .anticon.anticon-plus-square::after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  float: left;
  margin: auto;
  background: url("../Images//Plus.svg") no-repeat;
}
.scal-json-formatter .anticon.anticon-minus-square svg {
  display: none;
}
.scal-json-formatter .anticon.anticon-minus-square {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  float: left;
  margin: auto;
  background: url("../Images//TrashSimple.svg") no-repeat;
}
.scal-json-formatter .ant-btn:hover {
  border: 1px solid #fff !important;
  color: #15161b !important;
}
.scal-json-formatter .ant-btn {
  padding: 6px 16px !important;
  height: auto !important;
  width: auto !important;
  border-radius: 8px !important;
  color: var(--color-text-secondary) !important;
  background-color: var(--color-bg-secondary) !important;
  border: 1px solid var(--color-border-secondary);
}
.scal-json-formatter .ant-btn:hover {
  color: #fff !important;
  border-color: #fff;
}

.scal-json-formatter .ant-btn.ant-btn-primary {
  background-image: linear-gradient(
    0deg,
    #5f25d3 2.38%,
    #7825d0 100%
  ) !important;
  padding: 6px 16px !important;
  height: auto !important;
  width: auto !important;
  border-radius: 8px !important;
  border-color: var(--color-brand-primary) !important;
  color: #fff !important;
}
.scal-json-formatter .ant-btn.ant-btn-primary:hover {
  color: #fff !important;
  opacity: 0.9 !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: var(--color-text-primary) !important;
  background-color: #a577f3 !important;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #cdb1fc !important;
}
/*JSON editor customization end*/

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px var(--color-bg-primary) inset;
  -webkit-text-fill-color: var(--color-text-primary);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--color-text-primary);
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px var(--color-bg-primary);
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .blocking-loader {
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.container-main-cur {
  max-width: 100%;
  margin-inline: auto;
  padding-left: 5px;
  padding-right: 5px;
  /* border: 1px solid black; */
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  background-color: var(--color-bg-primary) !important;
}

p {
  margin-bottom: 0px !important;
  margin-top: 0;
}

/*Dot loader animation css*/
.dot-loader {
  width: 60px;
  display: flex;
  justify-content: space-evenly;
}

.dot-ball {
  list-style: none;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--color-text-primary);
}

.dot-ball:nth-child(1) {
  animation: bounce-1 1s ease-in-out infinite;
}

@keyframes bounce-1 {
  50% {
    transform: translateY(-15px);
  }
}

.dot-ball:nth-child(2) {
  animation: bounce-3 1s ease-in-out 0.3s infinite;
}

@keyframes bounce-2 {
  50% {
    transform: translateY(-15px);
  }
}

.dot-ball:nth-child(3) {
  animation: bounce-3 1s ease-in-out 0.6s infinite;
}

@keyframes bounce-3 {
  50% {
    transform: translateY(-15px);
  }
}
.fffff > * {
  color: red !important;
}

.loaderball,
.loaderball:before,
.loaderball:after {
  width: 4px;
  /* update this to control the size */
  aspect-ratio: 0.5;
  display: grid;
  background: radial-gradient(#000 50%, #0000 72%) center/100% 50% no-repeat;
  animation: load 1.2s infinite linear calc(var(--_s, 0) * 0.4s);
  transform: translate(calc(var(--_s, 0) * 150%));
}

.loaderball:before,
.loaderball:after {
  content: "";
  grid-area: 1/1;
}

.loaderball:before {
  --_s: -1;
}

.loaderball:after {
  --_s: 1;
}
@keyframes load {
  20% {
    background-position: top;
  }
  40% {
    background-position: bottom;
  }
  60% {
    background-position: center;
  }
}
.notiouter {
  padding: 32px 24px 32px 24px;
  gap: 32px;
  box-shadow: 0px 1px 2px 0px #1018280d;

  border-radius: 12px;
}

.tag-block-sm {
  padding: 2px 8px 2px 8px;

  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  word-break: keep-all;
}

.success-tag-block {
  border-radius: 6px;
  background: var(
    --Component-colors-Utility-Success-utility-success-50,
    #053321
  );
  border: 1px solid
    var(--Component-colors-Utility-Success-utility-success-200, #085d3a);
  color: #75e0a7;
}

.info-tag-block {
  border-radius: 6px;
  background: var(--Component-colors-Utility-Brand-utility-brand-50, #27115f);
  border: 1px solid
    var(--Component-colors-Utility-Brand-utility-brand-200, #4a1fb8);
  color: #bdb4fe;
}

.warning-tag-block {
  border-radius: 6px;
  background: var(
    --Component-colors-Utility-Warning-utility-warning-50,
    #4e1d09
  );
  border: 1px solid
    var(--Component-colors-Utility-Warning-utility-warning-200, #93370d);
  color: #fec84b;
}

.dark-tag-block {
  border-radius: 6px;
  background: var(--Component-colors-Utility-Gray-utility-gray-50, #161b26);
  border: 1px solid
    var(--Component-colors-Utility-Gray-utility-gray-200, #333741);
  color: white;
}
.secondary-tag-block {
  border-radius: 6px;
  background: var(
    --Component-colors-Utility-Blue-light-utility-blue-light-50,
    #062c41
  );
  border: 1px solid
    var(--Component-colors-Utility-Blue-light-utility-blue-light-200, #065986);
  color: #065986;
}
