.data-grid {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 1rem;
}
.block2grid2 {
  display: grid;
  padding-block: 4rem;
  grid-template-columns: minmax(0, 1fr);
  gap: 1rem;
}
.grid-outer-block {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 2rem;
  justify-items: center;
}
.data-grid-blok2 {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 1rem;
}
.datafooter {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}
.none-selected-block {
  padding: 16px;
  border: 2px solid var(--Colors-Border-border-brand-solid, #7a5af8);
  border-radius: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  gap: 1rem;
}
.selected-block {
  padding: 16px;
  cursor: pointer;
  justify-content: space-between;

  border: 1px solid var(--Colors-Border-border-secondary, #1f242f);
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.model-data-block {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-3xl) var(--spacing-xl) var(--spacing-3xl)
    var(--spacing-xl);
  gap: var(--spacing-xl);
  border-radius: var(--radius-md);
  border: 1px 0px 0px 0px;

  background-color: var(--color-bg-secondary);
  border: 1px solid var(--color-border-secondary, #1f242f);
}
.data-num {
  padding: var(--spacing-xxs) var(--spacing-md) var(--spacing-xxs)
    var(--spacing-sm);
  gap: var(--spacing-xs);
  border-radius: var(--radius-sm);
  border: 1px solid #333741;
  width: fit-content;
}
.data-tittle {
  color: var(--color-text-tertiary);
}

.data-pr-2 {
  padding: 0px 0px var(--spacing-9xl) 0px;
  gap: var(--spacing-7xl);
}
.block-pr4 {
  padding: var(--spacing-3xl) var(--spacing-3xl) var(--spacing-3xl)
    var(--spacing-3xl);
  gap: var(--spacing-4xl);
  border-radius: var(--radius-2xl);

  background-color: #161b26;
}
.img-res {
  width: 90%;
}
.data-pr-one {
  width: 95%;
  padding: var(--spacing-9xl) 0px var(--spacing-9xl) 0px;
}
.inputblock {
  flex-direction: column;
}
.text-filed-search {
  width: 100%;
}
@media (min-width: 375px) {
}

@media (min-width: 480px) {
}

@media (min-width: 640px) {
}

@media (min-width: 768px) {
  .text-filed-search {
    width: 350px;
  }
  .inputblock {
    flex-direction: row;
  }
  .block-pr4 {
    padding: var(--spacing-3xl) var(--spacing-7xl) var(--spacing-3xl)
      var(--spacing-7xl);
    gap: var(--spacing-4xl);
    border-radius: var(--radius-2xl);

    background-color: #161b26;
  }
  .data-pr-one {
    width: 50%;
    padding: var(--spacing-9xl) 0px var(--spacing-9xl) 0px;
  }
  .img-res {
    width: 70%;
  }
  .datafooter {
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }
  .data-grid {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(
        0,
        1fr
      );
    gap: 1.5rem;
  }
  .block2grid2 {
    display: grid;
    padding-block: 4rem;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: 2.5rem;
  }
  .grid-outer-block {
    display: grid;
    grid-template-columns:
      minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)
      minmax(0, 1fr);
    gap: 2rem;
  }
  .data-grid-blok2 {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: 2.5rem;
  }
}

@media (min-width: 830px) {
}

@media (min-width: 1024px) {
}

@media (min-width: 1280px) {
}
@media (min-width: 1300px) {
}

@media (min-width: 1400px) {
}
@media (min-width: 1500px) {
}
@media (min-width: 1600px) {
}

// @media (min-width: 375px) {
// }

// @media (min-width: 480px) {
// }

// @media (min-width: 640px) {
// }

// @media (min-width: 768px) {
// }

// @media (min-width: 830px) {
// }

// @media (min-width: 1024px) {
// }

// @media (min-width: 1280px) {
// }
// @media (min-width: 1300px) {
// }

// @media (min-width: 1400px) {
// }
// @media (min-width: 1500px) {
// }
// @media (min-width: 1600px) {
// }
