.outerBox {
  padding: 12px 8px 12px 8px;
  gap: 0px;
  border-radius: 4px;
  border: 1px 0px 0px 0px;
  margin-block: 0.5rem;
  border: 1px solid var(--Colors-Border-border-secondary, #1f242f);
}
.outerSelectedBorder {
  border: 2px solid var(--Colors-Border-border-brand-solid, #7a5af8);
}
.cardLogo {
  width: 46px;
  height: 32px;
}
