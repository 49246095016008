@use "../../../styles/_theme_values" as themeValue;
@use "../../../styles/_theme_creator" as themeAction;



.tag-block-main{
    padding: 24px;
    background: rgba(22, 27, 38, 1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    min-height: 200px;

    
}

.main-grid{
    display: grid;
    gap: 1rem;
    grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) ;
}